import React from "react"
import FotoGyt from "@components/pageCasosdeExito/image/imgPhotoEmpresa/gyt/FotoGyt.png"

//logo de empresas clientes
import Gyt from "@components/pageCasosdeExito/image/logoscustomer/Gyt.svg"

//iconos de modulos
import ivr from "@components/pageCasosdeExito/image/iconcards/ivr.svg"
import ivrmarron from "@components/pageCasosdeExito/image/iconcards/ivrmarron.svg"
import crm from "@components/pageCasosdeExito/image/iconcards/crm.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"

const textintro = (
  <span className="">
    <span className="text-underlined-rimac">
      “G&T Asesores, grupo
      <span className="strong-intro-casos">
        líder en gestión de cobranzas del norte del Perú,
      </span>
      gestiona sus operaciones en
      <span className="strong-intro-casos"> Beex
      </span>{" "}
      aprovechando sus características de
      <span className="strong-intro-casos">  
          CRM, marcaciones inteligentes y sistemas de respuesta de voz
          interactiva.
        ”
      </span>
    </span>
    <br />
    <span className="title-rimac">
      “G&T Asesores, grupo
      <span className="strong-intro-casos">
        {" "}
        líder en gestión de cobranzas del norte del Perú{" "}
      </span>{" "}
      gestiona sus operaciones en
      <span className="strong-intro-casos"> Beex</span> aprovechando sus
      características de
      <span className="strong-intro-casos">
        {" "}
        CRM, marcaciones inteligentes y sistemas de respuesta de voz
        interactiva.”
      </span>
    </span>
  </span>
)

const text = (
  <span>
    G&T es un importante grupo empresarial de Trujillo, capital de La Libertad,
    Perú; que brinda servicios integrales de alta calidad, que abarcan desde la
    asesoría para la prevención de contingencias legales hasta labores de
    consultorías.
    <br />
    <br />
    Ayudamos a las operaciones de G&T con Beex, brindando toda la suite de CRM,
    marcaciones automáticas y sistemas IVR, ideales para sus campañas de mora
    temprana con notificaciones por voz, y de doble vía, con enrutamiento
    inteligente hacia sus agentes.
    <br />
    <br />
    Con Beex, aplicamos los siguientes procesos que potencian su gestión
    diaria:
  </span>
)

const texttwo = (
  <span>
    “G&T Asesores se sumó como cliente este 2020 y nos enorgullece que hayan
    elegido Beex para migrar sus operaciones de Contact Center. Hemos logrado
    mejorar los resultados de sus campañas de cobranzas gracias a nuestras
    marcaciones automáticas y campañas de IVR inteligentes”
  </span>
)

const data = {
  intro: {
    image: Gyt,
    textbody: textintro,
    classlogo: "customerMornese",
  },
  description: {
    textbody: text,
    photo: FotoGyt,
    class:"section-description"
  },
  cards: [
    {
      title: "CRM",
      img: crm,
      info:
        "CRM para gestión de clientes en sus diferentes carteras, de diferentes tramos, como, preventivas, mora temprana, castigadas, judiciales y extra-judiciales.",
    },
    {
      title: "IVR",
      img: ivrmarron,
      info:
        "Campañas IVR doble vía, donde contactan a sus clientes con mensajes automáticos de voz y enrutan las respuestas exitosas a sus teleoperadores disponibles.",
    },
    {
      title: "IVR",
      img: ivr,
      info:
        "Campañas IVR informativas, donde envían notificaciones automáticas de voz a sus clientes, recordándoles sus acuerdos de pagos.",
    },
    {
      title: "Blending",
      img: blending,
      info:
        "Gestión Inbound para sus campañas, reduciendo los tiempos muertos de su operación y optimizando la gestión diaria de sus teleopeoradores gracias a nuestra función blending.",
    },
  ],
  descriptiontwo: {
    textbody: texttwo,
  },
}

export default data
